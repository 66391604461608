import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

// CSS
import css from './Footer.module.scss';

const Footer: FC = (): ReactElement => {
	const { t } = useTranslation('translation');

	return <footer className={css.Footer}>{HTMLReactParser(t('copyright'))}</footer>;
};

export default Footer;
