import { FC, ReactElement } from 'react';

// CSS
import css from './PromotedCover.module.scss';

interface PromotedCoverProps {
	readonly imageUrl: string;
}

const PromotedCover: FC<PromotedCoverProps> = (props: PromotedCoverProps): ReactElement => {
	return (
		<div className={css.PromotedCover} aria-hidden>
			<span>
				<img src={props.imageUrl} alt="" aria-hidden />
			</span>
		</div>
	);
};

export default PromotedCover;
