// Components
import Books from './components/books/Books';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import MainContent from './components/main-content/MainContent';

// CSS
import css from './App.module.scss';

function App() {
	return (
		<div className={css.App}>
			<Header />
			<MainContent>
				<Books />
			</MainContent>
			<Footer />
		</div>
	);
}

export default App;
