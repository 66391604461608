import { FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { BookProps } from '../../types';

// Components
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';

// CSS
import css from './Book.module.scss';

const Book: FC<BookProps> = (props: BookProps): ReactElement => {
	const { t } = useTranslation('translation');

	const BookWrapperNode: FC<{ url?: string; children: ReactNode }> = (props: {
		url?: string;
		children: ReactNode;
	}): ReactElement => {
		if (props.url)
			return (
				<a className={[css.Wrapper, css.Link].join(' ')} href={props.url} target="_blank" rel="noreferrer">
					{props.children}
				</a>
			);
		else return <div className={css.Wrapper}>{props.children}</div>;
	};

	return (
		<div className={css.Book} data-promoted={props.promomoted || null}>
			<BookWrapperNode url={props.url}>
				<figure>
					<img src={props.cover.thumbnail} alt={props.title} />
					<figcaption>
						<p className={css.Title}>{props.title}</p>
						<p className={props.url ? css.Shop : css.ComingSoon}>
							{t(props.url ? 'shop' : 'comingSoon')}
							{props.url && (
								<span className={css.ExternalIcon}>
									<ExternalLinkIcon />
								</span>
							)}
						</p>
					</figcaption>
				</figure>
			</BookWrapperNode>
		</div>
	);
};

export default Book;
