import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

// Types
import { BookProps } from '../../types';

// Components
import Book from '../book/Book';
import PromotedCover from '../promoted-cover/PromotedCover';
import { ReactComponent as ExternalLinkIcon } from '../../images/icons/external-link.svg';

// CSS
import css from './Books.module.scss';

const Books: FC = (): ReactElement => {
	const { t } = useTranslation(['books', 'translation']);
	const books: Array<BookProps> = (t('books:books', { returnObjects: true }) as Array<BookProps>).reverse();

	return (
		<div className={css.Books}>
			<PromotedCover imageUrl={books[0].cover.poster} />

			<ul className={css.List}>
				{books.map((book: BookProps, index: number): ReactElement => {
					return (
						<li key={book.isbn || index}>
							<Book {...book} promomoted={index === 0} />
						</li>
					);
				})}
			</ul>
			<p className={css.More}>
				<a href={String(t('translation:moreBooks.url'))} target="_blank" rel="noreferrer">
					{t('translation:moreBooks.label')}
					<ExternalLinkIcon />
				</a>
			</p>
		</div>
	);
};

export default Books;
