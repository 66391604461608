import { FC, ReactElement, ReactNode } from 'react';

// CSS
import css from './MainContent.module.scss';

interface MainContentProps {
	readonly children?: ReactElement | ReactNode;
}

const MainContent: FC<MainContentProps> = (props: MainContentProps): ReactElement => {
	return <main className={css.MainContent}>{props.children}</main>;
};

export default MainContent;
